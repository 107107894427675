<template>
  <div>
    <MainCard class="pa-4">
      <template v-slot:body>
        <v-card-title>
          <h3 class="d-block">
            {{ cbt.name }}
          </h3>
        </v-card-title>
        <v-card-text>
          <div class="mb-5 d-flex flex-column flex-xl-row flex-lg-row flex-md-row flex-sm-row justify-start align-start align-xl-center align-lg-center align-md-center align-sm-center">
            <div class="d-flex align-center my-2 mr-7">
              <v-img
                :src="icon.iconUser"
                width="20"
              ></v-img>
              <span class="ml-2">Admin CBT</span>
            </div>
            <div class="d-flex align-center my-2 mr-7">
              <v-img
                :src="icon.iconCalendar"
                width="20"
              ></v-img>
              <span class="ml-2">{{ cbt.start_date }}</span>
            </div>
            <div class="d-flex align-center my-2 mr-7">
              <v-img
                :src="icon.iconClock"
                width="20"
              ></v-img>
              <span class="ml-2">{{ cbt.duration }} Menit</span>
            </div>
            <div class="d-flex align-center my-2 mr-7">
              <v-img
                :src="icon.iconDocument"
                width="20"
              ></v-img>
              <span class="ml-2">{{ cbt.question.length }} Soal</span>
            </div>
            <div class="d-flex align-center my-2 mr-7">
              <span>Token:</span>
              <h2 class="text-h6 font-weight-bold ml-2">
                {{ cbt.token }}
              </h2>
            </div>
          </div>
          <v-divider></v-divider>
          <div class="mt-5">
            <h3 class="text-h6 font-weight-bold">
              Soal
            </h3>
            <div class="my-5">
              <v-card
                v-for="questionItem,index in cbt.question"
                :key="index"
                outlined
                class="my-2 elevation-0"
              >
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <v-row class="align-center">
                        <v-col
                          md="2"
                          cols="12"
                        >
                          <span>Soal No {{ index + 1 }}</span>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-chip
                            outlined
                            class="v-chip-light-bg success--text"
                            h-e-a-d
                            @click="checkDescription(questionItem.name)"
                          >
                            Lihat Deskripsi
                          </v-chip>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <span>A. {{ questionItem.choice_a }}</span>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <p>Jawaban</p>
                      {{ questionItem.answer.answer }}
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card>
            </div>
          </div>
        </v-card-text>
      </template>
    </MainCard>
    <ModalDialog
      :visible="modalDescription"
      :width="widthModal"
      header="Deskripsi Soal"
      :hidden-actions="false"
      @close-modal="modalDescription = false"
    >
      <template v-slot:body>
        <p v-html="detail"></p>
      </template>
      <template v-slot:footer>
        <v-btn
          color="primary"
          outlined
          large
          @click="modalDescription = false"
        >
          Tutup
        </v-btn>
      </template>
    </ModalDialog>
  </div>
</template>

<script>
import iconCalendar from '@/assets/icons/calendar.svg'
import iconClock from '@/assets/icons/clock.svg'
import iconDocument from '@/assets/icons/document-text.svg'
import iconUser from '@/assets/icons/user.svg'
import MainCard from '@/views/components/MainCard.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'

export default {
  name: 'CbtQuestion',
  components: {
    ModalDialog,
    MainCard,
  },
  data() {
    return {
      modalDescription: false,
      widthModal: 810,
      icon: {
        iconDocument,
        iconUser,
        iconClock,
        iconCalendar,
      },
      service: 'cbt',
      cbt: {},
      detail: {},
    }
  },
  mounted() {
    this.getCbt(this.$route.params.uuid)
  },
  methods: {
    checkDescription(data) {
      this.detail = data

      this.modalDescription = true
    },
    getCbt(uuid) {
      this.$services.ApiServices.get(this.service, uuid).then(({ data }) => {
        this.cbt = data.data
        console.log(this.cbt)
        localStorage.setItem('cbt', JSON.stringify(data.data))
      })
    },
  },
}
</script>

<style>
</style>
